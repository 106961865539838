import React, { useEffect, useState } from "react";
//components
import { DestinationDialog } from "./Dialogos/Localidades";
import { useStyles } from "./styles";
import UDatePicker from "../../../components/udatepicker";
import { GifsSource } from "../../../images/Gifs";
import ImageSlider from "../../../components/UImageSlider";
import USearchField from "../../../components/usearchfield";
import "./../../../fonts/fonts.css"
import RoomBooking from "../Modais/test";
import RoomOccupants from "../Modais/tests";
import MenuSelectField from "../Modais/SearchField";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FormattedDate from "./Dialogos/FormatedDates";
// Bibliotecas
import PropTypes from 'prop-types';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon-2x.png"
import { Icon } from "leaflet";
import Cookies from 'universal-cookie';
// material ui 4
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Badge, Box, Button, Card, CardContent, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Menu, MenuItem, Slide, Tab, Tabs, Toolbar, Tooltip, Typography, withStyles } from "@material-ui/core"
// Importação dos ícones de @material-ui/icons
import AddLocationIcon from '@material-ui/icons/AddLocation'; // Corrigido para AddLocation
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TranslateIcon from '@material-ui/icons/Translate';
import BusinessIcon from '@material-ui/icons/Business';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SpaIcon from '@material-ui/icons/Spa';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WifiIcon from '@material-ui/icons/Wifi';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import PoolIcon from '@material-ui/icons/Pool';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarsIcon from '@material-ui/icons/Stars';
// Importação dos componentes de @material-ui/lab
import { Pagination, Rating, Skeleton } from '@material-ui/lab';
import { Close, CloseOutlined, ErrorOutline, FilterListOutlined, FreeBreakfast, FreeBreakfastOutlined, LocalOffer, MoodBadOutlined, MoodOutlined, NewReleasesOutlined } from "@material-ui/icons";
import LinesTextField from "../../../components/LinesTextField";
import defaultlinesimg from '../../../images/Logos/logo_default.png';
import MapViewerHotels from "../../../components/MapViewer";
import { IconsSource } from "../../../images/Icons";
import { useSelector } from "react-redux";
import EmailChipInput from "../Modais/EmailInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const HotelMobile = props => {
    const classes = useStyles();
    const cookie = new Cookies();
    const [ratesTax, setRatesTax] = useState(0)
    const [ratesTotal, setRatesTotal] = useState(0)
    const [ratesTotalWithTax, setRatesTotalWithTax] = useState(0)
    const [filtredHotels, setFiltredHotels] = useState([])
    const [page, setPage] = useState(1); // A paginação do Material-UI começa em 1
    const [itensPerPage, setItensPerPage] = useState(10);
    const pages = Math.ceil(filtredHotels.length / itensPerPage);
    const start = (page - 1) * itensPerPage;
    const end = start + itensPerPage;
    const todasHoteis = filtredHotels.slice(start, end);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFilter, setOpenFilter] = useState(null);
    const [state, setState] = useState({
        checkedExclusive: false,
        checkedLaundry: false,
        checkedParking: false,
        checkedPool: false,
        checkedRestaurant: false,
    });
    const [searchnamehotel, setSearchnamehotel] = useState("");
    const [closeac1, setCloseac1] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false);
    const [openCotar, setOpenCotar] = useState(false);
    const dadosSessao = useSelector(state => state.permissao.dadosSessao);

    //ordenar tarifas
    const [tarifaOrder, setTarifaOrder] = useState(1)

    const onClickOrderTarifa = (value) => {
        setAnchorEl(null);
        setTarifaOrder(value);
    }

    const handleCloseOrderTarifas = () => {
        setAnchorEl(null);
    }

    const handleOpenCotar = () => {
        setOpenCotar(true)
    }

    const customIcon = new Icon({
        iconUrl: IconsSource.locIcon,
        iconSize: [38, 38]
    })

    const description = props.hotel && props.hotel.description ? props.hotel.description : '';
    const limite = 440;

    const handleClickString = () => setIsExpanded(!isExpanded);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setFiltredHotels(filterHotels());
    };

    function filterHotels(searchString = "") {
        setPage(1)
        searchString = searchString.toLowerCase();

        let listhotel = [];

        props.listHotel.hotels?.forEach(hotel => {
            let atendeExclusivo = state.checkedExclusive ? hotel.hasExclusive : true;
            let atendeLaundry = state.checkedLaundry ? hotel.hasLaundry : true;
            let atendeParking = state.checkedParking ? hotel.hasParking : true;
            let atendePool = state.checkedPool ? hotel.hasPool : true;
            let atendeRestaurant = state.checkedRestaurant ? hotel.hasRestaurant : true;
            if (atendeExclusivo && atendeLaundry && atendeParking && atendePool && atendeRestaurant) {
                listhotel.push(hotel);
            }
        });

        return listhotel;
    }

    const handleSearchName = (search) => {
        let searchString = search.toLowerCase();
        setSearchnamehotel(searchString)

        setPage(1)

        let filteredHotels = props.listHotel.hotels.filter((item) =>
            item.name.toLowerCase().includes(searchString)
        );

        if (search != "" && filteredHotels.length > 0) {
            setFiltredHotels(filteredHotels)
        } else {
            setFiltredHotels(props.listHotel.hotels)
        }
    };

    const handleCleanFilter = () => {
        setSearchnamehotel("");
        setState({
            checkedExclusive: false,
            checkedLaundry: false,
            checkedParking: false,
            checkedPool: false,
            checkedRestaurant: false,
        });
    }

    useEffect(() => {
        if (props.listHotel != null) {
            setFiltredHotels(filterHotels());
        }
    }, [state])

    useEffect(() => {
        if (props.listHotel != null) {
            setFiltredHotels(props.listHotel.hotels);
        }
    }, [props.listHotel])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        // Calculando a soma dos rates usando reduce
        setRatesTax(0)
        setRatesTotal(0)
        setRatesTotalWithTax(0)

        props.selectedRooms.map((value) => setRatesTax(ratesTax + value.hotel.room.rate.totalPriceWithoutTaxFeeBRL))
        props.selectedRooms.map((value) => setRatesTotal(ratesTotal + value.hotel.room.rate.totalPriceWithTaxFeeBRL))
        props.selectedRooms.map((value) => setRatesTotalWithTax(ratesTotalWithTax + value.hotel.room.rate.totalPriceWithTaxFeeBRL))
    }, [props.selectedRooms]);

    function getIconForAmenidade(amenidade) {
        switch (amenidade.toLowerCase()) {
            case 'berço disponível a pedido':
                return <ChildCareIcon style={{ marginRight: "10px" }} />;
            case 'aceita os principais cartões de crédito':
                return <CreditCardIcon style={{ marginRight: "10px" }} />;
            case 'equipe poliglota':
                return <TranslateIcon style={{ marginRight: "10px" }} />;
            case 'business center':
                return <BusinessIcon style={{ marginRight: "10px" }} />;
            case 'internet banda larga':
                return <NetworkCheckIcon style={{ marginRight: "10px" }} />;
            case 'recepção 24 horas':
                return <QueryBuilderIcon style={{ marginRight: "10px" }} />;
            case 'estacionamento':
                return <LocalParkingIcon style={{ marginRight: "10px" }} />;
            case 'estacionamento de ônibus':
                return <DirectionsBusIcon style={{ marginRight: "10px" }} />;
            case 'serviço de quarto 24 horas':
                return <RoomServiceIcon style={{ marginRight: "10px" }} />;
            case 'bar':
                return <LocalBarIcon style={{ marginRight: "10px" }} />;
            case 'restaurante':
                return <RestaurantMenuIcon style={{ marginRight: "10px" }} />;
            // case 'serviços de cocktails':
            //     return <FaCocktail />;
            case 'spa':
                return <SpaIcon style={{ marginRight: "10px" }} />;
            case 'ginásio':
                return <FitnessCenterIcon style={{ marginRight: "10px" }} />;
            case 'piscina':
                return <PoolIcon />;
            case 'aluguel de bicicletas':
                return <DirectionsBikeIcon style={{ marginRight: "10px" }} />;
            // case 'esportes aquáticos':
            //     return <FaPaddleboard />;
            case 'salas de reuniões':
                return <BusinessIcon style={{ marginRight: "10px" }} />;
            // case 'palco':
            //     return <FaTheaterMasks />;
            case 'serviços de aluguel de automóveis':
                return <DriveEtaIcon style={{ marginRight: "10px" }} />;
            // case 'auditório':
            //     return <FaChalkboard />;
            // case 'parquinho':
            //     return <FaPlayground />;
            case 'free wifi':
                return <WifiIcon style={{ marginRight: "10px" }} />;
            // case 'quadra de padel':
            //     return <FaPaddleboard />;
            // case 'babá a pedido':
            //     return <FaBabysitter />;
            // case 'academia de ginástica gratuita':
            //     return <FaDumbbell />;
            // case 'massagens a pedido':
            //     return <FaMassage />;
            // Adicione mais casos conforme necessário para outras amenidades
            default:
                return null; // Ícone padrão para amenidades não reconhecidas
        }
    }

    const handleCloseOrder = (value) => {
        if (value == 1) {
            // Ordena por preço mais baixo primeiro
            filtredHotels.sort((a, b) => a.lowerPrices.priceTotal - b.lowerPrices.priceTotal);
        } else if (value == 2) {
            // Ordena por preço mais alto primeiro
            filtredHotels.sort((a, b) => b.lowerPrices.priceTotal - a.lowerPrices.priceTotal);
        }
        setAnchorEl(null);
    }

    useEffect(() => {
        if (props.listHotel != null) {
            setFiltredHotels(props.listHotel.hotels);
        }
    }, [props.listHotel])

    return (
        <Grid container item xs={12}>
            {/* Pesquisa do usuario */}
            {props.step == 1 && props.listHotel == null ? (
                <>
                    <Grid item xs={12} className={classes.searchPage_backgroundTop}>
                        <img src={GifsSource.luggageCartIcon} width={50} alt="Hoteis" style={{ marginTop: '-16px' }}></img>
                    </Grid>
                    <Grid item xs={12} style={{
                        padding: "24px"
                    }}>
                        <Grid container item xs={12} className={classes.searchPage_card}>
                            <Grid item xs={12} style={{ marginBottom: "0px" }}>
                                <Typography style={{ alignSelf: "center", fontSize: "15px", display: "flex", marginBottom: "-10px" }}><AddLocationIcon className={classes.searchPage_iconW5margin} /> Destino</Typography>
                                <DestinationDialog msgAlt={"Digite ao menos 3 caracteres"} locations={props.locations} locationid={props.locationid} setLocationid={props.setLocationid} handleChangeAuto={props.handleChangeAuto} />
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12} md={2}>
                                    <UDatePicker date={props.checkin} setDate={props.setCheckin} disablePast={true} label="Entrada" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <UDatePicker date={props.checkout} setDate={props.setCheckout} minDate={props.checkin} disablePast={true} label="Saída" />
                                </Grid>
                            </Grid>
                            <hr className={classes.hrline}></hr>
                            <Grid container item xs={12} style={{ marginBottom: "18px" }}>
                                <MenuSelectField
                                    stepperAdultos={props.stepperAdultos}
                                    setStepperAdultos={props.setStepperAdultos}
                                    stepperCriancas={props.stepperCriancas}
                                    setStepperCriancas={props.setStepperCriancas}
                                    stepperRooms={props.stepperRooms}
                                    setStepperRooms={props.setStepperRooms}
                                    setQuartos={props.setRoomsForHotel}
                                    quartos={props.roomsForHotel}
                                    fullScreen={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={classes.button} onClick={() => props.handleSearchHotel()} variant="contained" fullWidth>
                                    Pesquisar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    {/* step continua 1 mas se conter uma lista de hoteis é renderizado */}
                    {props.listHotel != null && props.step == 1 ? (
                        <Grid container>
                            { /* NÂO REMOVER - Var com accordion só funciona se tiver 2 accordion renderizado */}
                            <Accordion style={{ display: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                ></AccordionSummary>
                            </Accordion>
                            { /* NÂO REMOVER */}
                            <Grid item xs={12} className={classes.listHotels_backgroundTop}>
                                <Accordion style={{ backgroundColor: '#c62828', marginTop: "10px" }} expanded={closeac1} onChange={() => setCloseac1(!closeac1)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container>
                                            <Grid item xs={8} style={{ textAlign: "left", justifyContent: "left" }}>
                                                <FormattedDate checkin={props.checkin} checkout={props.checkout} />
                                            </Grid>
                                            <Grid item xs={4} container style={{ textAlign: "right", justifyContent: "right" }}>
                                                <Typography className={classes.listHotels_accFont1}>
                                                    <MeetingRoomIcon style={{ marginRight: "5px" }} />
                                                    {props.roomsForHotel.length}
                                                </Typography>
                                                <Typography className={classes.listHotels_accFont1}>
                                                    <PersonIcon style={{ marginRight: "5px" }} />
                                                    {props.stepperAdultos + props.stepperCriancas}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: "white" }}>
                                        <Grid container item xs={12} style={{
                                            padding: "15px"
                                        }}>
                                            <Grid item xs={12} style={{ marginBottom: "10px", justifyContent: "center", marginBottom: "-8px" }}>
                                                <Typography style={{ alignSelf: "center", fontSize: "15px", display: "flex", marginBottom: "-10px" }}><AddLocationIcon className={classes.searchPage_iconW5margin} /> Destino</Typography>
                                                <DestinationDialog locations={props.locations} locationid={props.locationid} setLocationid={props.setLocationid} handleChangeAuto={props.handleChangeAuto} />
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} md={2}>
                                                    <UDatePicker date={props.checkin} setDate={props.setCheckin} disablePast={true} label="Entrada" />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <UDatePicker date={props.checkout} setDate={props.setCheckout} minDate={props.checkin} disablePast={true} label="Saída" />
                                                </Grid>
                                            </Grid>
                                            <hr className={classes.hrline}></hr>
                                            <Grid container item xs={12} style={{ marginBottom: "18px" }}>
                                                <MenuSelectField
                                                    stepperAdultos={props.stepperAdultos}
                                                    setStepperAdultos={props.setStepperAdultos}
                                                    stepperCriancas={props.stepperCriancas}
                                                    setStepperCriancas={props.setStepperCriancas}
                                                    stepperRooms={props.stepperRooms}
                                                    setStepperRooms={props.setStepperRooms}
                                                    setQuartos={props.setRoomsForHotel}
                                                    quartos={props.roomsForHotel}
                                                    fullScreen={false}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button className={classes.button} onClick={() => { props.handleSearchHotel(); setCloseac1(false); }} variant="contained" fullWidth>
                                                    Pesquisar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid container item xs={12} style={{ marginBottom: "-50px", padding: "20px" }}>
                                <Grid item xs={4} style={{ alignItems: "center", display: "flex" }}>
                                    <Typography style={{ fontSize: "16px", fontWeight: "600", fontFamily: "HindBold" }}>{filtredHotels.length} hotéis</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                                    <Button className={classes.listHotels_ButtonSecondary} aria-controls="simple-menu" aria-haspopup="true" onClick={() => setOpenFilter(true)}>
                                        <FilterListOutlined style={{ marginBottom: "4px", color: "#131313" }} /> Filtros
                                    </Button>
                                    {/* Dialogo totalmente ligado ao botão de cima *Filtros* */}
                                    <Dialog fullScreen open={openFilter} onClose={() => setOpenFilter(false)} TransitionComponent={Transition}>
                                        <AppBar className={classes.appBar} style={{ zIndex: 1 }}>
                                            <Toolbar>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={1}>
                                                        <IconButton edge="start" color="inherit" onClick={() => setOpenFilter(false)} aria-label="close">
                                                            <CloseOutlined />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={9} style={{ textAlign: "left", justifyContent: "left", display: "flex" }}>
                                                        <div style={{ marginLeft: "10px", display: "flex" }}>
                                                            <FormattedDate checkin={props.checkin} checkout={props.checkout} />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ width: "100%", textAlign: "right", display: "flex" }}>
                                                        <Button style={{ textTransform: "unset", fontWeight: "600" }} edge="end" autoFocus color="inherit" onClick={() => handleCleanFilter()}>
                                                            Remover
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Toolbar>
                                        </AppBar>
                                        <Grid container item xs={12} style={{
                                            boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                            borderRadius: "8px",
                                            padding: "14px",
                                            display: "block",
                                            zIndex: 2,
                                            position: 'relative',
                                            backgroundColor: "white",
                                            marginTop: "-8px"
                                        }}>
                                            <Grid item xs={12} style={{}}>
                                                <Typography style={{ fontSize: "20px", color: "#424242", fontWeight: "bold" }}>Buscar por nome do hotel</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{}}>
                                                <LinesTextField
                                                    placeholder="Pesquisar Nome"
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    onChange={e => handleSearchName(e.target.value)}
                                                    defaultValue={searchnamehotel}
                                                />
                                                <hr className={classes.hrline}></hr>
                                                {/* <Grid item xs={12}>
                                                    {props.locationUser != null ?
                                                        (
                                                            <Grid style={{ height: "100px" }}>
                                                                <MapContainer center={[props.locationUser.latitude, props.locationUser.longitude]} zoom={13}>
                                                                    <TileLayer
                                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                    />
                                                                    <Marker position={[props.locationUser.latitude, props.locationUser.longitude]} icon={customIcon}>
                                                                        <Popup>
                                                                            Seu local. <br />
                                                                        </Popup>
                                                                    </Marker>
                                                                </MapContainer>
                                                            </Grid>
                                                        ) : (
                                                            <Typography style={{ fontSize: "20px", color: "#424242", fontWeight: "bold" }}>Aceite a localização do seu navegador</Typography>
                                                        )}
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center", padding: "12px" }}>
                                                    {props.locationUser != null && props.listHotel != null ? (
                                                        <MapViewerHotels locUser={props.locationUser} locsHotels={props.listHotel.hotels} handleClickDisponiblity={props.handleClickDisponiblity} mobile={true}/>
                                                    ) : (
                                                        null
                                                    )}
                                                </Grid> */}
                                                {searchnamehotel.length > 5 && todasHoteis.length < 5 ? (
                                                    <Grid container item xs={12}>
                                                        {(todasHoteis).map((hotel) => (
                                                            <Grid container item xs={12} style={{
                                                                boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
                                                                borderRadius: "10px",
                                                                marginTop: "20px",
                                                                position: 'relative',
                                                                minHeight: "30px"
                                                            }}>
                                                                <Grid container item xs={12}>
                                                                    <Grid item xs={12} style={{
                                                                        padding: "20px",
                                                                        textAlign: "right",
                                                                        marginTop: "-28px",
                                                                        display: "block",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <Grid container item xs={12}>
                                                                            <Grid item xs={7} style={{ textAlign: "left", alignItems: "center", display: "flex" }}>
                                                                                <Typography style={{ fontWeight: "600", fontSize: "12px" }}>{hotel.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "14px", marginBottom: "10px" }}>Diária / <b style={{ fontFamily: "HindBold" }}>{hotel.lowerPrices?.pricePerDay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></Typography>
                                                                                <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "12px" }}>
                                                                                    Total para {Math.floor((new Date(props.checkout) - new Date(props.checkin)) / (1000 * 60 * 60 * 24))} diária(s)
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container item xs={12}>
                                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                                <Button style={{
                                                                                    color: "#353535", fontWeight: "500", fontSize: "10px",
                                                                                    fontWeight: "500",
                                                                                    backgroundColor: "#F1F1F1", // Cor de fundo cinza
                                                                                    padding: "8px 16px", // Espaçamento interno para parecer um botão
                                                                                    borderRadius: "16px", // Bordas arredondadas
                                                                                    cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                                                    display: "inline-block", // Faz com que o fundo cinza se ajuste ao texto
                                                                                    transition: "background-color 0.3s", // Adiciona transição para hover
                                                                                    "&:hover": {
                                                                                        backgroundColor: "#BDBDBD", // Muda a cor de fundo ao passar o mouse
                                                                                    }
                                                                                }}>{hotel.lowerPrices.planDescription}</Button>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Typography style={{ color: "#353535", fontWeight: "500", fontSize: "28px", fontFamily: "HindBold", }}>{hotel.lowerPrices?.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                                                                <Button className={classes.button} onClick={() => props.handleClickDisponiblity(hotel.id, hotel.rooms)}>
                                                                                    <Typography style={{ color: "#FFFFFF", fontSize: "12px", fontFamily: "HindBold", padding: "2px 23px 2px 23px" }}>Ver detalhes</Typography>
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : (
                                                    <div>

                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Typography style={{ fontSize: "20px", color: "#424242", fontWeight: "600" }}>Comodidades</Typography>
                                            </Grid>
                                            <Grid container item xs={12} style={{}}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={state.checkedExclusive ? { color: "#c62828" } : {}} checked={state.checkedExclusive} onChange={handleChange} name="checkedExclusive" />}
                                                        label={<Typography style={{ fontSize: "14px", fontWeight: "600" }}>Exclusivo para empresas</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={state.checkedLaundry ? { color: "#c62828" } : {}} checked={state.checkedLaundry} onChange={handleChange} name="checkedLaundry" />}
                                                        label={<Typography style={{ fontSize: "14px", fontWeight: "600" }}>Lavanderia</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={state.checkedParking ? { color: "#c62828" } : {}} checked={state.checkedParking} onChange={handleChange} name="checkedParking" />}
                                                        label={<Typography style={{ fontSize: "14px", fontWeight: "600" }}>Estacionamento</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={state.checkedPool ? { color: "#c62828" } : {}} checked={state.checkedPool} onChange={handleChange} name="checkedPool" />}
                                                        label={<Typography style={{ fontSize: "14px", fontWeight: "600" }}>Piscina</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={state.checkedRestaurant ? { color: "#c62828" } : {}} checked={state.checkedRestaurant} onChange={handleChange} name="checkedRestaurant" />}
                                                        label={<Typography style={{ fontSize: "14px", fontWeight: "600" }}>Restaurante</Typography>}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} style={{ padding: "10px", position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'white', zIndex: 1000 }}>
                                            <button
                                                className={classes.listHotels_ButtonPrimary}
                                                onClick={() => setOpenFilter(false)}
                                            >
                                                Pesquisar
                                            </button>
                                        </Grid>
                                    </Dialog>
                                    {/* Fim do dialog dos *Filtros* */}
                                </Grid>
                                <Grid item xs={4} style={{ justifyContent: "right", textAlign: "right", display: "flex" }}>


                                    <Button className={classes.listHotels_ButtonSecondary} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <ImportExportIcon style={{ marginBottom: "4px", color: "#131313" }} /> Ordenar
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseOrder}
                                    >
                                        {props.orderprices.map((value) => (
                                            <MenuItem onClick={() => handleCloseOrder(value.value)}>{value.label}</MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ padding: "20px", marginBottom: "40px" }}>
                                {todasHoteis[0]?.location?.id == 1027131 && (
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", borderRadius: "4px", color: "#ffffff", background: "#3f51b5", padding: "2px" }}>
                                        <LocalOffer style={{ margin: "5px", color: "#ffffff" }} />
                                        <Typography variant="body2">Atenção! Os hotéis de Criciúma têm ofertas especiais negociadas com a Linestur para a sua empresa. Os preços listados a seguir são os valores normais. Para verificar as condições, consulte sua agente designada, seja por solicitação ou via WhatsApp.</Typography>
                                    </Grid>
                                )}
                                {(todasHoteis).map((item, index) => (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            className={classes.listHotels_card}
                                        >
                                            <Grid item xs={12} style={{ position: "relative" }}>
                                                <img
                                                    src={item.thumbImage}
                                                    onError={(e) => { e.target.src = defaultlinesimg; }}
                                                    alt={"Imagem não encontrada"}
                                                    className={classes.listHotels_cardimg}
                                                />
                                                {todasHoteis[0]?.location?.id == 1027131 && (
                                                    <div style={{ borderRadius: "50%", background: "#3f51b5", color: "#ffffff", right: "10px", bottom: "10px", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                                                        <LocalOffer style={{ color: "#ffffff" }} />
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid container item xs={12} style={{ position: 'relative', zIndex: 1, marginTop: '-25px' }}>
                                                <Grid item xs={12} style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
                                                    <Typography style={{ fontWeight: "700", fontSize: "18px", fontFamily: "HindBold", marginTop: "20px" }}>{item.name}.</Typography>
                                                    <Typography style={{ color: "#898989", fontWeight: "500", fontSize: "12px" }}>{item.location.address}</Typography>
                                                    <Rating
                                                        style={{ display: "none" }}
                                                        name="read-only"
                                                        value={item.award.id}
                                                        readOnly
                                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                    /> {/*AQUI NÂO REMOVER*/}
                                                    <Rating name="read-only" value={item.award.id} emptyIcon={<StarBorderIcon fontSize="inherit" />} readOnly size="medium" />
                                                    <Grid container style={{ marginTop: "2%" }}>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{
                                                    padding: "20px",
                                                    textAlign: "right",
                                                    marginTop: "-28px"
                                                }}>
                                                    <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "14px", marginBottom: "10px" }}>Diária / <b style={{ fontFamily: "HindBold" }}>{item.lowerPrices?.pricePerDay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></Typography>
                                                    <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "12px" }}>
                                                        Total para {Math.floor((new Date(props.checkout) - new Date(props.checkin)) / (1000 * 60 * 60 * 24))} diária(s)
                                                    </Typography>
                                                    <Grid container item xs={12}>
                                                        <Grid item xs={6} style={{ textAlign: "left" }}>
                                                            <Button style={{
                                                                color: "#353535", fontWeight: "500", fontSize: "10px",
                                                                fontWeight: "500",
                                                                backgroundColor: "#F1F1F1", // Cor de fundo cinza
                                                                padding: "8px 16px", // Espaçamento interno para parecer um botão
                                                                borderRadius: "16px", // Bordas arredondadas
                                                                cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                                display: "inline-block", // Faz com que o fundo cinza se ajuste ao texto
                                                                transition: "background-color 0.3s", // Adiciona transição para hover
                                                                "&:hover": {
                                                                    backgroundColor: "#BDBDBD", // Muda a cor de fundo ao passar o mouse
                                                                }
                                                            }}>{item.lowerPrices.planDescription}</Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography style={{ color: "#353535", fontWeight: "500", fontSize: "28px", fontFamily: "HindBold", }}>{item.lowerPrices?.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} container style={{
                                                    marginTop: "-34px", flexDirection: "column",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    padding: "20px",
                                                    textAlign: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <Button className={classes.button} onClick={() => props.handleClickDisponiblity(item.id, item.rooms)}>
                                                        <Typography style={{ color: "#FFFFFF", fontSize: "12px", fontFamily: "HindBold", padding: "5px 23px 5px 23px" }}>Ver detalhes</Typography>
                                                    </Button>
                                                    {/* <ViewInfoHotelDesktop id={item.id}/> */}
                                                </Grid>
                                                <Grid item xs={12} style={{
                                                    marginTop: "-40px",
                                                    padding: "20px",
                                                    textAlign: "center",
                                                    justifyContent: "center",
                                                    borderTop: "1px solid #EFEFEF"
                                                }}>
                                                    <Typography style={{ color: "#353535", fontWeight: "500", fontSize: "14px", marginTop: "10px", marginBottom: "-8px" }}>Taxas inclusas</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))}
                                <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", marginTop: "23px", marginBottom: "20px" }}>
                                    <Pagination
                                        count={pages} // Total de páginas
                                        page={page} // Página atual
                                        onChange={handleChangePage} // Função para mudar a página
                                        variant="outlined"
                                        shape="rounded"
                                    />
                                </Grid>
                            </Grid>

                            <Grid></Grid>
                        </Grid>
                    ) : (
                        <>
                            {props.hotel != null && props.step == 2 ? (
                                <Grid container item xs={12} style={{ marginBottom: "40px" }}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} style={{ backgroundColor: '#c62828', textAlign: "center", justifyContent: "center" }}>
                                            <Grid item xs={12} style={{ textAlign: "left", justifyContent: "flex-start", marginTop: "14px", marginLeft: "10px", marginBottom: "10px" }}>
                                                <Typography onClick={
                                                    () => { props.setStep(1) }
                                                } style={{ color: "white", lineHeight: "24px", display: "inline-flex", alignItems: "center", fontSize: "13px" }}><ArrowBackIosIcon /> {props.locationid != null ? props.locationid?.subtitle : "Cidade"}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ zIndex: 1 }}>
                                            <ImageSlider images={props.hotel.images} />
                                        </Grid>
                                        <Grid item xs={12} style={{ zIndex: 1 }}>
                                            <Grid item xs={12} style={{
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "10px",
                                                width: '100%',
                                                height: 'auto',
                                                flexShrink: 0,
                                                fill: '#FFF',
                                                top: "40%",
                                                filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                borderRadius: '10px',
                                                backgroundColor: "#FFFFFF",
                                                boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                borderBottom: 'none',
                                                zIndex: 2,
                                                marginTop: "-10px"
                                            }}>
                                                <Grid item xs={12} style={{ padding: "20px", marginBottom: "0px" }}>
                                                    <Typography style={{ fontSize: "21px", fontWeight: "600", lineHeight: "32px", color: "#10121F", fontFamily: "HindBold" }}>{props.hotel.name}</Typography>
                                                    <Rating
                                                        style={{ display: "none" }}
                                                        name="read-only"
                                                        value={4}
                                                        readOnly
                                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                    /> {/*AQUI NÂO REMOVER*/}
                                                    <Rating name="read-only" value={props.hotel.award.rating} emptyIcon={<StarBorderIcon fontSize="inherit" />} readOnly size="small" />
                                                    <Typography style={{ fontSize: "11px", lineHeight: "32px" }}>{props.hotel.location.address}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginBottom: "5px", marginTop: "-20px" }}>
                                                    <Grid container item xs={12} style={{ padding: "10px" }}>
                                                        {todasHoteis[0]?.location?.id == 1027131 && (
                                                            <Grid item xs={12} style={{ display: "flex", alignItems: "center", borderRadius: "4px", color: "#ffffff", background: "#3f51b5", padding: "2px" }}>
                                                                <LocalOffer style={{ margin: "5px", color: "#ffffff" }} />
                                                                <Typography variant="body2">Atenção! Os hotéis de Criciúma têm ofertas especiais negociadas com a Linestur para a sua empresa. Os preços listados a seguir são os valores normais. Para verificar as condições, consulte sua agente designada, seja por solicitação ou via WhatsApp.</Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <Typography style={{ marginLeft: "10px", marginTop: "10px", fontWeight: "600", fontSize: "20px", fontFamily: "HindBold" }}>Sobre a hospedagem</Typography>
                                                        </Grid>
                                                        <Grid id="#Quartos" container item xs={12} style={{ marginTop: "10px" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "sans-serif", whiteSpace: "pre-wrap", margin: "0 8px" }}>
                                                                {description.length > limite && !isExpanded ? `${description.substring(0, limite)}...` : description}
                                                            </Typography>
                                                            {description.length > limite && (
                                                                <Typography style={{ fontSize: "14px", fontFamily: "sans-serif", whiteSpace: "pre-wrap", margin: "0 8px", marginLeft: "5px", fontSize: "12px", cursor: "pointer" }} onClick={handleClickString}>
                                                                    {isExpanded ? 'ver menos' : 'ver mais'}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{ padding: "20px", marginTop: "-20px" }}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ fontSize: "18px", fontWeight: "600", lineHeight: "32px", color: "#10121F", fontFamily: "HindBold" }}>Principais comodidades</Typography>
                                                    </Grid>
                                                    {props.hotel.amenities.map((comodo, index) => (
                                                        <>
                                                            {getIconForAmenidade(comodo.name) != null ? (
                                                                <Grid container item xs={12} key={index} style={{ padding: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography style={{ fontSize: "14px", fontWeight: "500", lineHeight: "24px", display: "inline-flex", alignItems: "center" }}>
                                                                            {getIconForAmenidade(comodo.name)} {comodo.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                        </>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ padding: "1px", marginTop: "10px" }}>
                                                <Grid container item xs={12} style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    borderRadius: '10px',
                                                }}>
                                                    {/* <Grid item xs={12} style={{ padding: "20px" }}>
                                                        {props.hotelprices != null ? (
                                                            <>
                                                                {props.hotelprices?.map((value) => (
                                                                    <>
                                                                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                                                            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>{value.roomTypeDescription}</Typography>
                                                                            <Typography style={{ fontSize: "14px", fontWeight: "500" }}>{value.accomodationTypeDescription} {value.irrevocable ? (<span style={{ color: "#c62828" }}> - Irrevogável</span>) : (null)}</Typography>
                                                                            <Typography style={{
                                                                                backgroundColor: "#03a691", marginTop: "10px", color: "#FFFFFF", fontWeight: "600", maxWidth: "auto", fontWeight: "600",
                                                                                width: "max-content", padding: "4px"
                                                                            }}>{value.availabilitys[0].quantity > 10 ? "Muitos quartos disponiveis" : value.availabilitys[0].quantity + " Quartos"}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {value.description?.split(', ').map((p) => (
                                                                                <Typography>
                                                                                    {p}
                                                                                </Typography>
                                                                            ))}
                                                                        </Grid>
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                                                    <Typography style={{ fontSize: "20px", fontWeight: "600" }}><Skeleton variant="text" /></Typography>
                                                                    <Typography style={{ fontSize: "14px", fontWeight: "500" }}><Skeleton variant="text" /></Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Skeleton variant="rect" width={210} height={118} />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid> */}
                                                    <Grid item xs={12} style={{ padding: "20px", justifyContent: "right" }}>
                                                        <Grid container item xs={12}>
                                                            <Grid container item xs={12}>
                                                                <Grid item xs={8}>
                                                                    {props.hotelprices != null ? (
                                                                        <Button style={{
                                                                            fontWeight: "600",
                                                                            lineHeight: "15px",
                                                                            fontSize: "15px",
                                                                            fontFamily: "HindBold",
                                                                            alignItems: "center",
                                                                            color: "#131313",
                                                                            marginLeft: "-14px"
                                                                        }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                                            <ImportExportIcon style={{ marginBottom: "4px", color: "#050404" }} /> Ordenar Tarifas
                                                                        </Button>
                                                                    ) : (null)}
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={anchorEl}
                                                                        keepMounted
                                                                        open={Boolean(anchorEl)}
                                                                        onChange={(e) => onClickOrderTarifa(e.target.value)}
                                                                        onClose={handleCloseOrderTarifas}
                                                                    >
                                                                        {props.orderprices.map((value) => (
                                                                            <MenuItem value={value.value} onClick={() => onClickOrderTarifa(value.value)}>{value.label}</MenuItem>
                                                                        ))}
                                                                    </Menu>
                                                                    <Typography style={{ fontSize: "18px" }}>Planos:</Typography>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button style={{
                                                                        fontWeight: "600",
                                                                        lineHeight: "15px",
                                                                        fontSize: "15px",
                                                                        fontFamily: "HindBold",
                                                                        alignItems: "center",
                                                                        color: "#131313"
                                                                    }} aria-controls="simple-menu" aria-haspopup="true" onClick={() => setOpenCotar(true)}>
                                                                        <Badge badgeContent={props.listCotacao.length} color="error">
                                                                            <HowToVoteIcon style={{ marginBottom: "4px", color: "#131313", marginRight: "6px" }} />
                                                                        </Badge>
                                                                        Cotação
                                                                    </Button>

                                                                </Grid>
                                                                <Dialog onClose={() => setOpenCotar(false)} aria-labelledby="customized-dialog-title" open={openCotar} fullScreen={true}>
                                                                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenCotar(false)}>
                                                                        <Grid container justifyContent="space-between" alignItems="center">
                                                                            <Grid item>
                                                                                Cotação para hotel
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <IconButton
                                                                                    aria-label="close"
                                                                                    onClick={() => setOpenCotar(false)}
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        right: 8,
                                                                                        top: 8,
                                                                                        color: (theme) => theme.palette.grey[500],
                                                                                    }}
                                                                                >
                                                                                    <Close />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {props.listCotacao.length !== 0 && (
                                                                            <Grid item xs={12}>
                                                                                <EmailChipInput
                                                                                    setEmailsCotar={props.setEmailsCotar}
                                                                                    emailsCotar={props.emailsCotar}
                                                                                    fullScreen={true}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </DialogTitle>
                                                                    <DialogContent dividers>
                                                                        <Grid container item xs={12}>
                                                                            {props.listCotacao.length == 0 ? (
                                                                                <>
                                                                                    <Grid item xs={12} style={{ width: "500px", height: "200px", textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                                                        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                                                                                            Selecione tarifas para cotação
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (<>
                                                                                <Grid item xs={12}>
                                                                                    <Typography>Cotação - {cookie.get('org').toLowerCase()}</Typography>
                                                                                </Grid>
                                                                            </>)}
                                                                            {props.listCotacao?.map((acotar) => (
                                                                                <>
                                                                                    <Grid item xs={12} style={{ padding: "5px" }}>
                                                                                        <Card sx={{
                                                                                            minWidth: 325,
                                                                                            justifyContent: "right",
                                                                                            filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                                            borderRadius: '30px', // Adicionando sombra nos outros lados
                                                                                            borderBottom: 'none', // Removendo a sombra inferior,
                                                                                            backgroundColor: "#FFFFFF",
                                                                                            filter: 'none',  // Remove a sombra
                                                                                            boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 1)",
                                                                                            borderBottom: 'none',
                                                                                            marginTop: "-45px"
                                                                                        }}>
                                                                                            <CardContent>
                                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                    <Typography style={{ fontWeight: "600", color: "#c62828" }} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                                                        {acotar.hotelname}
                                                                                                    </Typography>
                                                                                                    <IconButton onClick={() => props.handleClickRemoverDaListCotar(acotar.id)}>
                                                                                                        <ClearIcon />
                                                                                                    </IconButton>
                                                                                                </Box>
                                                                                                <Typography variant="body2">
                                                                                                    {acotar.hotelendereco}
                                                                                                </Typography>
                                                                                                <Typography variant="body2">
                                                                                                    {acotar.description} (BRL)
                                                                                                </Typography>
                                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                                                                                                    <Typography variant="body2" component="div">
                                                                                                        <Box component="span" sx={{ borderRadius: '4px', padding: '2px 6px', marginRight: '8px' }}>
                                                                                                            {acotar.cafedamanha ? (
                                                                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                                                                                                                    <FreeBreakfast style={{ marginRight: "7px" }} /> café da manhã
                                                                                                                </Typography>
                                                                                                            ) :
                                                                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                                                                                                                    <HighlightOffIcon style={{ marginRight: "7px" }} /> sem café da manhã
                                                                                                                </Typography>
                                                                                                            }
                                                                                                        </Box>
                                                                                                        <Box component="span" sx={{ borderRadius: '4px', padding: '2px 6px', marginRight: '8px' }}>
                                                                                                            {!acotar.nonRefundable ? (
                                                                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                                                                                                                    <StarsIcon style={{ marginRight: "7px" }} /> Reembolsável
                                                                                                                </Typography>
                                                                                                            ) :
                                                                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                                                                                                                    <NewReleasesOutlined style={{ marginRight: "7px" }} /> Não reembolsável
                                                                                                                </Typography>
                                                                                                            }
                                                                                                        </Box>
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" component="div">
                                                                                                        R${acotar.priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
                                                                                                        <Typography variant="h8" component="div" style={{ textAlign: "right", fontSize: "12px" }}>
                                                                                                            / Diária
                                                                                                        </Typography>
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', marginTop: '8px' }}>
                                                                                                    Total: R${acotar.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')} inclui impostos e taxas
                                                                                                </Typography>
                                                                                            </CardContent>
                                                                                        </Card>
                                                                                    </Grid>
                                                                                </>
                                                                            ))}
                                                                        </Grid>
                                                                    </DialogContent>
                                                                    {props.listCotacao.length != 0 ? (
                                                                        <DialogActions>
                                                                            <Button variant="contained" style={{ color: "white", backgroundColor: "#c62828" }} autoFocus onClick={() => { props.handleClickEnviarCotar(); setOpenCotar(false) }} color="primary">
                                                                                Enviar cotação
                                                                            </Button>
                                                                        </DialogActions>
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                </Dialog>


                                                            </Grid>
                                                            <RoomBooking
                                                                hotelplan={props.hotelprices}
                                                                numberOfRooms={props.roomsForHotel.length}
                                                                roomSelections={props.roomSelections}
                                                                setRoomSelections={props.setRoomSelections}
                                                                handleChangeRoom={props.handleChangeRoom}
                                                                hotelrules={props.hotelrules}
                                                                hotel={props.hotel}
                                                                setListCotacao={props.setListCotacao}
                                                                listCotacao={props.listCotacao}
                                                                checkin={props.checkin}
                                                                checkout={props.checkout}
                                                                adutnumber={props.stepperAdultos}
                                                                criancasnumber={props.stepperCriancas}
                                                                orderTarifas={tarifaOrder}
                                                            />
                                                        </Grid>
                                                        {/* <Grid container item xs={12}>
                                                            <Grid item xs={12} style={{
                                                                display: "grid",
                                                                alignContent: "center", marginTop: "10px"
                                                            }}>
                                                                <Button
                                                                    fullWidth
                                                                    className={classes.button}
                                                                    onClick={() => {
                                                                        props.handleChangeRoom()
                                                                    }}
                                                                >
                                                                    Reservar
                                                                </Button>
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <hr className={classes.hrline}></hr>
                                            {props.hotelprices?.priceResponseList?.map((value) =>
                                                <Grid item xs={12} style={{ padding: "7px" }}>
                                                    <Grid container item xs={12} style={{
                                                        padding: "18px",
                                                        width: '100%',
                                                        height: 'auto',
                                                        flexShrink: 0,
                                                        fill: '#FFF',
                                                        top: "40%",
                                                        filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                        borderRadius: '10px',
                                                        backgroundColor: "#FFFFFF",
                                                        boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                        borderBottom: 'none',
                                                    }}>
                                                        <Grid container item xs={12}>
                                                            <Grid item xs={12}>
                                                                <Typography style={{ fontSize: "20px", textDecoration: "underline", color: "#c62828" }}>{value.hotel.room.roomTypeDescription}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                                                <Typography style={{ fontSize: "14px" }}>{value.hotel.room.rate.mealPlanDescription}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={props.selectedRooms.some(selectedRoom => selectedRoom.productTokenIndex === value.productTokenIndex)} onChange={() => props.handleChangeRoom(value)} name="checkedExclusive" />}
                                                                    label={<Typography style={{ fontSize: "12px" }}>Selecionar</Typography>}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container item xs={4}>
                                                            <Grid item xs={12}>
                                                                <Typography style={{ marginTop: "10px", fontSize: "16px" }}>A partir de</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "19px" }}>{value.hotel.room.rate.totalPriceSale?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={8} style={{
                                                            textAlign: "right", alignSelf: "center",
                                                            marginTop: "10px", marginLeft: "3px"
                                                        }}>
                                                            <Typography style={{ color: "#FFFFFF", fontWeight: "500", fontSize: "13px", backgroundColor: "#227950", borderRadius: "4px", padding: "5px", textAlign: "center" }}>
                                                                Totalmente reembolsável
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    {props.step == 3 ? (
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} style={{ backgroundColor: '#c62828', textAlign: "center", justifyContent: "center" }}>
                                                <Grid item xs={12} style={{ textAlign: "left", justifyContent: "flex-start", marginTop: "14px", marginLeft: "10px", marginBottom: "10px" }}>
                                                    {/* <Grid item xs={12} onClick={
                                        () => { props.setStep(0) }
                                    }
                                    style={{ textAlign: "left", justifyContent: "flex-start", marginTop: "14px", marginLeft: "10px" }}>
                                    <Typography style={{ color: "white", lineHeight: "24px", display: "inline-flex", alignItems: "center", fontSize: "13px" }}><ArrowBackIosIcon /> {props.locationid != null ? props.locationid?.subtitle : "Cidade"}</Typography>
                                </Grid> */}
                                                    <Typography onClick={
                                                        () => { props.setStep(2) }
                                                    } style={{ color: "white", lineHeight: "24px", display: "inline-flex", alignItems: "center", fontSize: "13px" }}><ArrowBackIosIcon /> {props.locationid != null ? props.locationid?.subtitle : "Cidade"}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} style={{ padding: "6px" }}>
                                                <Grid container item xs={12} style={{
                                                    padding: "20px",
                                                    width: '100%',
                                                    height: 'auto',
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                                    borderRadius: '10px',
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                                    borderBottom: 'none',
                                                }}>


                                                    <Grid container item xs={9}>
                                                        <Grid container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography style={{ fontSize: "18px", fontFamily: "HindBold", color: "#414040" }}>Hospedagem</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography style={{ fontSize: "14px", color: "#414040" }}>
                                                                {props.stepperAdultos} Adulto(s){props.stepperCriancas > 0 ? (", " + props.stepperCriancas + " Criança(s)") : null}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={3} style={{ maxHeight: "10px" }}>
                                                        <img
                                                            src={props.hotel.images[1].thumb}
                                                            style={{ width: '100%', display: 'block', height: "auto", borderRadius: "8px" }}
                                                        />
                                                    </Grid>
                                                    <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                        {/* <Grid item xs={12} style={{ height: "50%", max }}>
                                                            <img
                                                                src={props.hotel.images[1].thumb}
                                                                style={{ width: '100%', display: 'block', height: "50%", borderRadius: "8px" }}
                                                            />
                                                        </Grid> */}
                                                        <Grid container item xs={12}>
                                                            <Grid item xs={12}>
                                                                <Rating
                                                                    style={{ display: "none" }}
                                                                    name="read-only"
                                                                    value={4}
                                                                    readOnly
                                                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                                /> {/*AQUI NÂO REMOVER*/}
                                                                <Rating name="read-only" value={props.hotel.award.rating} emptyIcon={<StarBorderIcon fontSize="inherit" />} readOnly size="small" />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography style={{ fontFamily: "HindBold", color: "#414040" }}>
                                                                    {props.hotel.name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ marginTop: "10px", fontSize: "12px" }}>{props.hotel.location.address}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "16px" }}>
                                                        <Typography style={{ fontSize: "12px", color: "#414040" }}>{props.roomSelections.length} Quarto(s), {props.stepperAdultos} Adulto(s){props.stepperCriancas > 0 ? (", " + props.stepperCriancas + " Criança(s)") : null}</Typography>

                                                        {props.roomSelections.map((item) => (
                                                            <>
                                                                <Typography style={{ fontSize: "11px", color: "#414040" }}>{item.name}</Typography>
                                                                <Grid container item xs={12} style={{ display: "grid" }}>
                                                                    <Grid item xs={12} style={{ marginBottom: "5px", marginTop: "5px" }}>
                                                                        {
                                                                            item.plano?.cafedamanha ? (
                                                                                <Chip
                                                                                    icon={<FreeBreakfastOutlined style={{ color: "#2573B3" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Café da manhã incluído"
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <Chip
                                                                                        icon={<ErrorOutline style={{ color: "#B32525" }} />}
                                                                                        style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                        label="Sem café da manhã"
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }

                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            item.plano?.nonRefundable ? (
                                                                                <Chip
                                                                                    icon={<MoodBadOutlined style={{ color: "#B32525" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Não reembolsável"
                                                                                />
                                                                            ) : (
                                                                                <Chip
                                                                                    icon={<MoodOutlined style={{ color: "#25B33D" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Reembolsável"
                                                                                />
                                                                            )
                                                                        }

                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                        <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#6B6B6B" }}>Entrada</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#6B6B6B", marginLeft: "8px" }}>Saída</Typography>
                                                            </Grid>
                                                            <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                                <Grid item xs={6}>
                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#464646" }}>
                                                                        {new Date(props.checkin).toLocaleDateString('pt-BR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ borderLeft: '1px solid #0000003B', padding: '0px 8px 8px 8px' }}>
                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#464646" }}>
                                                                        {new Date(props.checkout).toLocaleDateString('pt-BR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid container item xs={12} style={{
                                                    justifyContent: "center", textAlign: "center", padding: "20px",
                                                    width: '100%',
                                                    height: 'auto',
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                                    borderRadius: '10px',
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                                    borderBottom: 'none',
                                                }}>

                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                        <Typography style={{ fontFamily: "HindMedium", fontSize: "23px" }}>Quem vai viajar?</Typography>
                                                    </Grid>
                                                    {props.roomSelections.map((value, index) => (
                                                        <Grid item xs={12} container style={{
                                                            marginTop: "10px",
                                                        }}>
                                                            <>
                                                                <Grid container item xs={12} style={{}}>
                                                                    {index != 0 ? (
                                                                        <hr className={classes.hrline} style={{ marginBottom: "15px", marginTop: "15px" }}></hr>
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                        <Typography>Quarto {index + 1} - {value.name} ({value.type})</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ justifyContent: "center", textAlign: "center", marginBottom: "50px" }}>
                                                                        <RoomOccupants
                                                                            roomNumber={index + 1}
                                                                            valueRoom={value}
                                                                            pessoas={props.pessoas}
                                                                            pessoa={props.pessoa}
                                                                            setPessoa={props.setPessoa}
                                                                            centrosdecusto={props.centrosdecusto}
                                                                            centrodecusto={props.centrodecusto}
                                                                            setCentroDeCusto={props.setCentroDeCusto}
                                                                            paxsForRooms={props.paxsForRooms}
                                                                            setPaxsForRooms={props.setPaxsForRooms}
                                                                            stepperAdultos={props.stepperAdultos}
                                                                            stepperCriancas={props.stepperCriancas}
                                                                            handleClickAdicionarHospede={props.handleClickAdicionarHospede}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12}>
                                                        <Typography style={{ fontFamily: "HindMedium", fontSize: "23px" }}>Solicitante da reserva</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <USearchField itens={props.solicitantes} value={props.solicitante} setValue={props.setSolicitante} label="Solicitante" />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{
                                                    padding: "10px",
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                    backgroundColor: "#FFFFFF",
                                                    filter: 'none',  // Remove a sombra
                                                    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                    borderBottom: 'none',
                                                    marginTop: "10px",
                                                    marginBottom: "60px"
                                                }}>
                                                    <Grid container item xs={12} style={{ padding: "20px" }}>
                                                        <Grid item xs={12}>
                                                            <Typography style={{ fontSize: "28px", textAlign: "Left", fontFamily: "HindMedium", color: "#242222" }}>
                                                                Total da pagar
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography style={{ fontSize: "18px", color: "#3B3939", fontFamily: "HindMedium" }}>
                                                                Valor final
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <Typography style={{ fontSize: "18px", fontFamily: "HindBold", color: "#3B3939" }}>
                                                                {props.valuesHotel.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: "right", justifyContent: "right", marginTop: "-8px", marginBottom: "-8px" }}>
                                                            <Typography style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                Inclui {props.valuesHotel.totaltax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} de impostos e taxas
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                                        <Button fullWidth className={classes.button} onClick={() => props.handleClickReservar()}>
                                                            Reservar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} container style={{ padding: "20px" }}>
                                            <Grid item xs={12} style={{
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "10px",
                                                width: '100%',
                                                height: 'auto',
                                                flexShrink: 0,
                                                fill: '#FFF',
                                                top: "40%",
                                                filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                borderRadius: '10px',
                                                backgroundColor: "#FFFFFF",
                                                boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                borderBottom: 'none',
                                            }}>
                                                <Skeleton style={{ height: "300px" }} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )
            }
        </Grid >
    )
}
