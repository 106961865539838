
import LogoAzul from '../images/Logos/logo_azul.png';
import LogoDefault from '../images/Logos/logo_default.png';
import LogoDelta from '../images/Logos/logo_delta.png';
import LogoGol from '../images/Logos/logo_gol.png';
import LogoItapemirim from '../images/Logos/logo_itapemirim.png';
import LogoLatam from '../images/Logos/logo_latam.jpg';
import LogoMap from '../images/Logos/logo_map.png';
import LogoVoePass from '../images/Logos/logo_voepass.png';

export const EnumCompany = {
    companies: {
        aegean: 'A3',
        aeroLines: 'AR',
        aeroflot: 'SU',
        aeroMexico: 'AM',
        airCanada: 'AC',
        airChina: 'CA',
        airEuropa: 'UX',
        airFrance: 'AF',
        alaskaAirlines: 'AS',
        amaszonas: 'Z8',
        americanAirlines: 'AA',
        ana: 'NH',
        austrian: 'OS',
        avianca: 'AV',
        azul: 'AD',
        boa: 'OB',
        britishAirways: 'BA',
        brusselsAirlines: 'SN',
        cathayPacific: 'CX',
        chinaSouthern: 'CZ',
        condor: 'DE',
        copaAirlines: 'CM',
        delta: 'DL',
        emirates: 'EK',
        etihad: 'EY',
        finnair: 'AY',
        gol: 'G3',
        hawaiin: 'HA',
        iberia: 'IB',
        itapemirim: '8I',
        japanAirlines: 'JL',
        jetBlue: 'B6',
        kenyaAirways: 'KQ',
        klm: 'KL',
        koreanAir: 'KE',
        latam: 'LA',
        latamJJ: 'JJ',
        lot: 'LO',
        lufthansa: 'LH',
        luxair: 'LG',
        map: '7M',
        qantas: 'QF',
        qatar: 'QR',
        singapore: 'SQ',
        skyAirline: 'H2',
        swiss: 'LX',
        taag: 'DT',
        tapPortugal: 'TP',
        turkishAirlines: 'TK',
        united: 'UA',
        voePass: '2Z',
        vueling: 'VY',
        jetSmart: 'JA',
        itaAirways: 'AZ'
    },

    companiesLogo: {
        aegean: LogoDefault,
        aeroLines: LogoDefault,
        aeroflot: LogoDefault,
        aeroMexico: LogoDefault,
        airCanada: LogoDefault,
        airChina: LogoDefault,
        airEuropa: LogoDefault,
        airFrance: LogoDefault,
        alaskaAirlines: LogoDefault,
        amaszonas: LogoDefault,
        americanAirlines: LogoDefault,
        ana: LogoDefault,
        austrian: LogoDefault,
        avianca: LogoDefault,
        azul: LogoAzul,
        boa: LogoDefault,
        britishAirways: LogoDefault,
        brusselsAirlines: LogoDefault,
        cathayPacific: LogoDefault,
        chinaSouthern: LogoDefault,
        condor: LogoDefault,
        copaAirlines: LogoDefault,
        delta: LogoDelta,
        emirates: LogoDefault,
        etihad: LogoDefault,
        finnair: LogoDefault,
        gol: LogoGol,
        hawaiin: LogoDefault,
        iberia: LogoDefault,
        ietBlue: LogoDefault,
        itapemirim: LogoItapemirim,
        japanAirlines: LogoDefault,
        kenyaAirways: LogoDefault,
        klm: LogoDefault,
        koreanAir: LogoDefault,
        latam: LogoLatam,
        lot: LogoDefault,
        lufthansa: LogoDefault,
        luxair: LogoDefault,
        map: LogoMap,
        qantas: LogoDefault,
        qatar: LogoDefault,
        singapore: LogoDefault,
        skyAirline: LogoDefault,
        swiss: LogoDefault,
        taag: LogoDefault,
        tapPortugal: LogoDefault,
        turkishAirlines: LogoDefault,
        united: LogoDefault,
        voePass: LogoVoePass,
        vueling: LogoDefault,
        jetSmart: LogoDefault,
        itaAirways: LogoDefault
    },

    companiesNome: {
        aegean: 'Aegean Airlines',
        aeroLines: 'Aerolíneas Argentinas',
        aeroflot: 'Aeroflot',
        aeroMexico: 'Aeromexico',
        airCanada: 'Air Canada',
        airChina: 'Air China',
        airEuropa: 'Air Europa',
        airFrance: 'Air France',
        alaskaAirlines: 'Alaska Airlines',
        amaszonas: 'Amaszonas',
        americanAirlines: 'American Airlines',
        ana: 'All Nippon Airways',
        austrian: 'Austrian Airlines',
        avianca: 'Avianca',
        azul: 'Azul',
        boa: 'Boliviana de Aviacion',
        britishAirways: 'British Airways',
        brusselsAirlines: 'Brussels Airlines',
        cathayPacific: 'Cathay Pacific Airways',
        chinaSouthern: 'China Southern Airlines',
        condor: 'Condor',
        copaAirlines: 'Copa Airlines',
        delta: 'Delta Airlines',
        emirates: 'Emirates',
        etihad: 'Etihad Airways',
        finnair: 'Finnair',
        gol: 'Gol',
        hawaiin: 'Hawaiian Airlines',
        iberia: 'Iberia Lineas Aereas',
        itapemirim: 'Itapemirim',
        japanAirlines: 'Japan Airlines',
        jetBlue: 'Jetblue Airways',
        kenyaAirways: 'Kenya Airways',
        klm: 'KLM',
        koreanAir: 'Korean Air Lines',
        latam: 'Latam',
        lot: 'LOT Polish Airlines',
        lufthansa: 'Lufthansa',
        luxair: 'Luxair',
        map: 'Map',
        qantas: 'Qantas Airways',
        qatar: 'Qatar Airways',
        singapore: 'Singapore Airlines',
        skyAirline: 'Sky Airline',
        swiss: 'SWISS',
        taag: 'Linhas Aereas de Angola',
        tapPortugal: 'TAP Portugal',
        turkishAirlines: 'Turkish Airlines',
        united: 'United',
        voePass: 'VoePass',
        vueling: 'Vueling Airlines',
        jetSmart: 'JetSMART Airlines',
        itaAirways: "Ita Airways"
    },

    companiesUrlLOW: {
        aegean: 'https://linesturdiag.blob.core.windows.net/logo-companhia/A3%20(Aegean)/A3_low.png',
        aeroLines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AR%20(Aero%20Lineas)/AR_low.png',
        aeroflot: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SU%20(Aeroflot)/SU_low.png',
        aeroMexico: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AM%20(Aero%20Mexico)/AM_low.png',
        airCanada: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AC%20(Air%20Canada)/AC_low.png',
        airChina: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CA%20(Air%20China)/CA_low.png',
        airEuropa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/UX%20(Air%20Europa)/UX_low.png',
        airFrance: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AF%20(Air%20France)/AF_low.png',
        alaskaAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AS%20(Alaska%20Airlines)/AS_low.png',
        amaszonas: 'https://linesturdiag.blob.core.windows.net/logo-companhia/Z8%20(Amaszonas)/Z8_low.png',
        americanAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AA%20(American%20Airlines)/AA_low.png',
        ana: 'https://linesturdiag.blob.core.windows.net/logo-companhia/NH%20(Ana)/NH_low.png',
        austrian: 'https://linesturdiag.blob.core.windows.net/logo-companhia/OS%20(Austrian)/OS_low.png',
        avianca: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AV%20(Avianca)/AV_low.png',
        azul: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AD%20(Azul)/AD_low.png',
        boa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/OB%20(BoA)/OB_low.png',
        britishAirways: 'https://linesturdiag.blob.core.windows.net/logo-companhia/BA%20(British%20Airways)/BA_low.png',
        brusselsAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SN%20(Brussels%20airlines)/SN_low.png',
        cathayPacific: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CX%20(Cathay%20Pacific)/CX_low.png',
        chinaSouthern: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CZ%20(China%20Southern)/CZ_low.png',
        condor: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DE%20(Condor)/DE_low.png',
        copaAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CM%20(Copa%20Airlines)/CM_low.png',
        delta: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DL%20(Delta%20Airlines)/DL_low.png',
        emirates: 'https://linesturdiag.blob.core.windows.net/logo-companhia/EK%20(Emirates)/EK_low.png',
        etihad: 'https://linesturdiag.blob.core.windows.net/logo-companhia/EY%20(Etihad)/EY_low.png',
        finnair: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AY%20(Finnair)/AY_low.png',
        gol: 'https://linesturdiag.blob.core.windows.net/logo-companhia/G3%20(Gol)/G3_low.png',
        hawaiin: 'https://linesturdiag.blob.core.windows.net/logo-companhia/HA%20(Hawaiin)/HA_low.png',
        iberia: 'https://linesturdiag.blob.core.windows.net/logo-companhia/IB%20(Iberia)/IB_low.png',
        itapemirim: 'https://linesturdiag.blob.core.windows.net/logo-companhia/8I%20(Itapemirim)/8I_low.png',
        japanAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/JL%20(Japan%20Airlines)/JL_low.png',
        jetBlue: 'https://linesturdiag.blob.core.windows.net/logo-companhia/B6%20(JetBlue)/B6_low.png',
        kenyaAirways: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KQ%20(Kenya%20Airways)/KQ_low.png',
        klm: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KL%20(Klm)/KL_low.png',
        koreanAir: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KE%20(Korean%20Air)/KE_low.png',
        latam: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LA%20(Latam)/LA_low.png',
        lot: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LO%20(LOT)/LO_low.png',
        lufthansa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LH%20(Lufthansa)/LH_low.png',
        luxair: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LG%20(Luxair)/LG_low.png',
        map: 'https://linesturdiag.blob.core.windows.net/logo-companhia/7M%20(Map)/7M_low.png',
        qantas: 'https://linesturdiag.blob.core.windows.net/logo-companhia/QF%20(Qantas)/QF_low.png',
        qatar: 'https://linesturdiag.blob.core.windows.net/logo-companhia/QR%20(Qatar)/QR_low.png',
        singapore: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SQ%20(Singapore)/SQ_low.png',
        skyAirline: 'https://linesturdiag.blob.core.windows.net/logo-companhia/H2%20(Sky%20Airline)/H2_low.png',
        swiss: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LX%20(Swiss)/LX_low.png',
        taag: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DT%20(Taag)/DT_low.png',
        tapPortugal: 'https://linesturdiag.blob.core.windows.net/logo-companhia/TP%20(%20Tap%20Portugal)/TP_low.png',
        turkishAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/UX%20(Air%20Europa)/UX_low.png',
        united: 'https://linesturdiag.blob.core.windows.net/logo-companhia/UA%20(United)/UA_low.png',
        voePass: 'https://linesturdiag.blob.core.windows.net/logo-companhia/2Z%20(Voepass)/2Z_low.png',
        vueling: 'https://linesturdiag.blob.core.windows.net/logo-companhia/VY%20(Vueling)/VY_low.png',
        jetSmart: 'https://linesturdiag.blob.core.windows.net/logo-companhia/JA%20(JetSMART Airlines)/JA_low.png',
        itaAirways: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AZ%20(Ita%20Airways)/AZ_low.png'

    },
    companiesUrlHIGH: {
        aegean: 'https://linesturdiag.blob.core.windows.net/logo-companhia/A3%20(Aegean)/A3_high.png',
        aeroLines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AR%20(Aero%20Lineas)/AR_high.png',
        aeroflot: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SU%20(Aeroflot)/SU_high.png',
        aeroMexico: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AM%20(Aero%20Mexico)/AM_high.png',
        airCanada: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AC%20(Air%20Canada)/AC_high.png',
        airChina: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CA%20(Air%20China)/CA_high.png',
        airEuropa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/UX%20(Air%20Europa)/UX_high.png',
        airFrance: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AF%20(Air%20France)/AF_high.png',
        alaskaAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AS%20(Alaska%20Airlines)/AS_high.png',
        amaszonas: 'https://linesturdiag.blob.core.windows.net/logo-companhia/Z8%20(Amaszonas)/Z8_high.png',
        americanAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AA%20(American%20Airlines)/AA_high.png',
        ana: 'https://linesturdiag.blob.core.windows.net/logo-companhia/NH%20(Ana)/NH_high.png',
        austrian: 'https://linesturdiag.blob.core.windows.net/logo-companhia/OS%20(Austrian)/OS_high.png',
        avianca: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AV%20(Avianca)/AV_high.png',
        azul: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AD%20(Azul)/AD_high.png',
        boa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/OB%20(BoA)/OB_high.png',
        britishAirways: 'https://linesturdiag.blob.core.windows.net/logo-companhia/BA%20(British%20Airways)/BA_high.png',
        brusselsAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SN%20(Brussels%20airlines)/SN_low.png',
        cathayPacific: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CX%20(Cathay%20Pacific)/CX_high.png',
        chinaSouthern: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CZ%20(China%20Southern)/CZ_high.png',
        condor: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DE%20(Condor)/DE_high.png',
        copaAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/CM%20(Copa%20Airlines)/CM_high.png',
        delta: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DL%20(Delta%20Airlines)/DL_high.png',
        emirates: 'https://linesturdiag.blob.core.windows.net/logo-companhia/EK%20(Emirates)/EK_high.png',
        etihad: 'https://linesturdiag.blob.core.windows.net/logo-companhia/EY%20(Etihad)/EY_high.png',
        finnair: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AY%20(Finnair)/AY_high.png',
        gol: 'https://linesturdiag.blob.core.windows.net/logo-companhia/G3%20(Gol)/G3_high.png',
        hawaiin: 'https://linesturdiag.blob.core.windows.net/logo-companhia/HA%20(Hawaiin)/HA_high.png',
        iberia: 'https://linesturdiag.blob.core.windows.net/logo-companhia/IB%20(Iberia)/IB_high.png',
        itapemirim: 'https://linesturdiag.blob.core.windows.net/logo-companhia/8I%20(Itapemirim)/8I_high.png',
        japanAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/JL%20(Japan%20Airlines)/JL_high.png',
        jetBlue: 'https://linesturdiag.blob.core.windows.net/logo-companhia/B6%20(JetBlue)/B6_high.png',
        kenyaAirways: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KQ%20(Kenya%20Airways)/KQ_high.png',
        klm: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KL%20(Klm)/KL_high.png',
        koreanAir: 'https://linesturdiag.blob.core.windows.net/logo-companhia/KE%20(Korean%20Air)/KE_high.png',
        latam: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LA%20(Latam)/LA_high.png',
        latamJJ: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LA%20(Latam)/LA_high.png',
        lot: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LO%20(LOT)/LO_low.png',
        lufthansa: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LH%20(Lufthansa)/LH_high.png',
        luxair: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LG%20(Luxair)/LG_low.png',
        map: 'https://linesturdiag.blob.core.windows.net/logo-companhia/7M%20(Map)/7M_high.png',
        qantas: 'https://linesturdiag.blob.core.windows.net/logo-companhia/QF%20(Qantas)/QF_high.png',
        qatar: 'https://linesturdiag.blob.core.windows.net/logo-companhia/QR%20(Qatar)/QR_high.png',
        singapore: 'https://linesturdiag.blob.core.windows.net/logo-companhia/SQ%20(Singapore)/SQ_high.png',
        skyAirline: 'https://linesturdiag.blob.core.windows.net/logo-companhia/H2%20(Sky%20Airline)/H2_high.png',
        swiss: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LX%20(Swiss)/LX_high.png',
        taag: 'https://linesturdiag.blob.core.windows.net/logo-companhia/DT%20(Taag)/DT_high.png',
        tapPortugal: 'https://linesturdiag.blob.core.windows.net/logo-companhia/TP%20(%20Tap%20Portugal)/TP_high.png',
        turkishAirlines: 'https://linesturdiag.blob.core.windows.net/logo-companhia/TK%20(Turkish%20Airlines)/TK_high.png',
        united: 'https://linesturdiag.blob.core.windows.net/logo-companhia/UA%20(United)/UA_high.png',
        voePass: 'https://linesturdiag.blob.core.windows.net/logo-companhia/2Z%20(Voepass)/2Z_high.png',
        vueling: 'https://linesturdiag.blob.core.windows.net/logo-companhia/VY%20(Vueling)/VY_high.png',
        jetSmart: 'https://linesturdiag.blob.core.windows.net/logo-companhia/JA%20(JetSMART%20Airlines)/JA_high.png',
        itaAirways: "https://linesturdiag.blob.core.windows.net/logo-companhia/AZ%20(Ita%20Airways)/AZ_high.png"

    },
    companiesUrlMINI: {
        azul: 'https://linesturdiag.blob.core.windows.net/logo-companhia/AD%20(Azul)/AD_mini.png',
        latam: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LA%20(Latam)/LA_mini.png',
        latamJJ: 'https://linesturdiag.blob.core.windows.net/logo-companhia/LA%20(Latam)/LA_mini.png',
        gol: 'https://linesturdiag.blob.core.windows.net/logo-companhia/G3%20(Gol)/G3_mini.png',
    },

    getLogoCompanhiaAereaEnum: function (value) {
        let dto = {
            logo: null,
            nome: null,
            url_low: null,
            url_high: null,
            url_mini: null,
        };
        switch (value) {
            case this.companies.aegean:
                return dto = {
                    nome: this.companiesNome.aegean,
                    logo: this.companiesLogo.aegean,
                    url_low: this.companiesUrlLOW.aegean,
                    url_high: this.companiesUrlHIGH.aegean
                }
            case this.companies.aeroLines:
                return dto = {
                    nome: this.companiesNome.aeroLines,
                    logo: this.companiesLogo.aeroLines,
                    url_low: this.companiesUrlLOW.aeroLines,
                    url_high: this.companiesUrlHIGH.aeroLines,
                }
            case this.companies.aeroflot:
                return dto = {
                    nome: this.companiesNome.aeroflot,
                    logo: this.companiesLogo.aeroflot,
                    url_low: this.companiesUrlLOW.aeroflot,
                    url_high: this.companiesUrlHIGH.aeroflot,
                }
            case this.companies.aeroMexico:
                return dto = {
                    nome: this.companiesNome.aeroMexico,
                    logo: this.companiesLogo.aeroMexico,
                    url_low: this.companiesUrlLOW.aeroMexico,
                    url_high: this.companiesUrlHIGH.aeroMexico,
                }
            case this.companies.airCanada:
                return dto = {
                    nome: this.companiesNome.airCanada,
                    logo: this.companiesLogo.airCanada,
                    url_low: this.companiesUrlLOW.airCanada,
                    url_high: this.companiesUrlHIGH.airCanada,
                }
            case this.companies.airChina:
                return dto = {
                    nome: this.companiesNome.airChina,
                    logo: this.companiesLogo.airChina,
                    url_low: this.companiesUrlLOW.airChina,
                    url_high: this.companiesUrlHIGH.airChina,
                }
            case this.companies.airEuropa:
                return dto = {
                    nome: this.companiesNome.airEuropa,
                    logo: this.companiesLogo.airEuropa,
                    url_low: this.companiesUrlLOW.airEuropa,
                    url_high: this.companiesUrlHIGH.airEuropa,
                }
            case this.companies.airFrance:
                return dto = {
                    nome: this.companiesNome.airFrance,
                    logo: this.companiesLogo.airFrance,
                    url_low: this.companiesUrlLOW.airFrance,
                    url_high: this.companiesUrlHIGH.airFrance,
                }
            case this.companies.alaskaAirlines:
                return dto = {
                    nome: this.companiesNome.alaskaAirlines,
                    logo: this.companiesLogo.alaskaAirlines,
                    url_low: this.companiesUrlLOW.alaskaAirlines,
                    url_high: this.companiesUrlHIGH.alaskaAirlines,
                }
            case this.companies.amaszonas:
                return dto = {
                    nome: this.companiesNome.amaszonas,
                    logo: this.companiesLogo.amaszonas,
                    url_low: this.companiesUrlLOW.amaszonas,
                    url_high: this.companiesUrlHIGH.amaszonas,
                }
            case this.companies.americanAirlines:
                return dto = {
                    nome: this.companiesNome.americanAirlines,
                    logo: this.companiesLogo.americanAirlines,
                    url_low: this.companiesUrlLOW.americanAirlines,
                    url_high: this.companiesUrlHIGH.americanAirlines,
                }
            case this.companies.ana:
                return dto = {
                    nome: this.companiesNome.ana,
                    logo: this.companiesLogo.ana,
                    url_low: this.companiesUrlLOW.ana,
                    url_high: this.companiesUrlHIGH.ana,
                }
            case this.companies.austrian:
                return dto = {
                    nome: this.companiesNome.austrian,
                    logo: this.companiesLogo.austrian,
                    url_low: this.companiesUrlLOW.austrian,
                    url_high: this.companiesUrlHIGH.austrian,
                }
            case this.companies.avianca:
                return dto = {
                    nome: this.companiesNome.avianca,
                    logo: this.companiesLogo.avianca,
                    url_low: this.companiesUrlLOW.avianca,
                    url_high: this.companiesUrlHIGH.avianca,
                }
            case this.companies.azul:
                return dto = {
                    nome: this.companiesNome.azul,
                    logo: this.companiesLogo.azul,
                    url_low: this.companiesUrlLOW.azul,
                    url_high: this.companiesUrlHIGH.azul,
                    url_mini: this.companiesUrlMINI.azul,
                }
            case this.companies.boa:
                return dto = {
                    nome: this.companiesNome.boa,
                    logo: this.companiesLogo.boa,
                    url_low: this.companiesUrlLOW.boa,
                    url_high: this.companiesUrlHIGH.boa,
                }
            case this.companies.britishAirways:
                return dto = {
                    nome: this.companiesNome.britishAirways,
                    logo: this.companiesLogo.britishAirways,
                    url_low: this.companiesUrlLOW.britishAirways,
                    url_high: this.companiesUrlHIGH.britishAirways,
                }
            case this.companies.brusselsAirlines:
                return dto = {
                    nome: this.companiesNome.brusselsAirlines,
                    logo: this.companiesLogo.brusselsAirlines,
                    url_low: this.companiesUrlLOW.brusselsAirlines,
                    url_high: this.companiesUrlHIGH.brusselsAirlines,
                }
            case this.companies.cathayPacific:
                return dto = {
                    nome: this.companiesNome.cathayPacific,
                    logo: this.companiesLogo.cathayPacific,
                    url_low: this.companiesUrlLOW.cathayPacific,
                    url_high: this.companiesUrlHIGH.cathayPacific,
                }
            case this.companies.chinaSouthern:
                return dto = {
                    nome: this.companiesNome.chinaSouthern,
                    logo: this.companiesLogo.chinaSouthern,
                    url_low: this.companiesUrlLOW.chinaSouthern,
                    url_high: this.companiesUrlHIGH.chinaSouthern,
                }
            case this.companies.condor:
                return dto = {
                    nome: this.companiesNome.condor,
                    logo: this.companiesLogo.condor,
                    url_low: this.companiesUrlLOW.condor,
                    url_high: this.companiesUrlHIGH.condor,
                }
            case this.companies.copaAirlines:
                return dto = {
                    nome: this.companiesNome.copaAirlines,
                    logo: this.companiesLogo.copaAirlines,
                    url_low: this.companiesUrlLOW.copaAirlines,
                    url_high: this.companiesUrlHIGH.copaAirlines,
                }
            case this.companies.delta:
                return dto = {
                    nome: this.companiesNome.delta,
                    logo: this.companiesLogo.delta,
                    url_low: this.companiesUrlLOW.delta,
                    url_high: this.companiesUrlHIGH.delta,
                }
            case this.companies.emirates:
                return dto = {
                    nome: this.companiesNome.emirates,
                    logo: this.companiesLogo.emirates,
                    url_low: this.companiesUrlLOW.emirates,
                    url_high: this.companiesUrlHIGH.emirates,
                }
            case this.companies.etihad:
                return dto = {
                    nome: this.companiesNome.etihad,
                    logo: this.companiesLogo.etihad,
                    url_low: this.companiesUrlLOW.etihad,
                    url_high: this.companiesUrlHIGH.etihad,
                }
            case this.companies.finnair:
                return dto = {
                    nome: this.companiesNome.finnair,
                    logo: this.companiesLogo.finnair,
                    url_low: this.companiesUrlLOW.finnair,
                    url_high: this.companiesUrlHIGH.finnair,
                }
            case this.companies.gol:
                return dto = {
                    nome: this.companiesNome.gol,
                    logo: this.companiesLogo.gol,
                    url_low: this.companiesUrlLOW.gol,
                    url_high: this.companiesUrlHIGH.gol,
                    url_mini: this.companiesUrlMINI.gol,
                }
            case this.companies.hawaiin:
                return dto = {
                    nome: this.companiesNome.hawaiin,
                    logo: this.companiesLogo.hawaiin,
                    url_low: this.companiesUrlLOW.hawaiin,
                    url_high: this.companiesUrlHIGH.hawaiin,
                }
            case this.companies.iberia:
                return dto = {
                    nome: this.companiesNome.iberia,
                    logo: this.companiesLogo.iberia,
                    url_low: this.companiesUrlLOW.iberia,
                    url_high: this.companiesUrlHIGH.iberia,
                }
            case this.companies.itapemirim:
                return dto = {
                    nome: this.companiesNome.itapemirim,
                    logo: this.companiesLogo.itapemirim,
                    url_low: this.companiesUrlLOW.itapemirim,
                    url_high: this.companiesUrlHIGH.itapemirim,
                }
            case this.companies.itaAirways:
                return dto = {
                    nome: this.companiesNome.itaAirways,
                    logo: this.companiesLogo.itaAirways,
                    url_low: this.companiesUrlLOW.itaAirways,
                    url_high: this.companiesUrlHIGH.itaAirways,
                }
            case this.companies.japanAirlines:
                return dto = {
                    nome: this.companiesNome.japanAirlines,
                    logo: this.companiesLogo.japanAirlines,
                    url_low: this.companiesUrlLOW.japanAirlines,
                    url_high: this.companiesUrlHIGH.japanAirlines,
                }
            case this.companies.jetBlue:
                return dto = {
                    nome: this.companiesNome.jetBlue,
                    logo: this.companiesLogo.jetBlue,
                    url_low: this.companiesUrlLOW.jetBlue,
                    url_high: this.companiesUrlHIGH.jetBlue,
                }
            case this.companies.kenyaAirways:
                return dto = {
                    nome: this.companiesNome.kenyaAirways,
                    logo: this.companiesLogo.kenyaAirways,
                    url_low: this.companiesUrlLOW.kenyaAirways,
                    url_high: this.companiesUrlHIGH.kenyaAirways,
                }
            case this.companies.klm:
                return dto = {
                    nome: this.companiesNome.klm,
                    logo: this.companiesLogo.klm,
                    url_low: this.companiesUrlLOW.klm,
                    url_high: this.companiesUrlHIGH.klm,
                }
            case this.companies.koreanAir:
                return dto = {
                    nome: this.companiesNome.koreanAir,
                    logo: this.companiesLogo.koreanAir,
                    url_low: this.companiesUrlLOW.koreanAir,
                    url_high: this.companiesUrlHIGH.koreanAir,
                }
            case this.companies.latam:
                return dto = {
                    nome: this.companiesNome.latam,
                    logo: this.companiesLogo.latam,
                    url_low: this.companiesUrlLOW.latam,
                    url_high: this.companiesUrlHIGH.latam,
                    url_mini: this.companiesUrlMINI.latam,
                }
            case this.companies.latamJJ:
                return dto = {
                    nome: this.companiesNome.latam,
                    logo: this.companiesLogo.latam,
                    url_low: this.companiesUrlLOW.latam,
                    url_high: this.companiesUrlHIGH.latam,
                    url_mini: this.companiesUrlMINI.latam,
                }
            case this.companies.lot:
                return dto = {
                    nome: this.companiesNome.lot,
                    logo: this.companiesLogo.lot,
                    url_low: this.companiesUrlLOW.lot,
                    url_high: this.companiesUrlHIGH.lot,
                }
            case this.companies.lufthansa:
                return dto = {
                    nome: this.companiesNome.lufthansa,
                    logo: this.companiesLogo.lufthansa,
                    url_low: this.companiesUrlLOW.lufthansa,
                    url_high: this.companiesUrlHIGH.lufthansa,
                }
            case this.companies.luxair:
                return dto = {
                    nome: this.companiesNome.luxair,
                    logo: this.companiesLogo.luxair,
                    url_low: this.companiesUrlLOW.luxair,
                    url_high: this.companiesUrlHIGH.luxair,
                }
            case this.companies.map:
                return dto = {
                    nome: this.companiesNome.map,
                    logo: this.companiesLogo.map,
                    url_low: this.companiesUrlLOW.map,
                    url_high: this.companiesUrlHIGH.map,
                }
            case this.companies.qantas:
                return dto = {
                    nome: this.companiesNome.qantas,
                    logo: this.companiesLogo.qantas,
                    url_low: this.companiesUrlLOW.qantas,
                    url_high: this.companiesUrlHIGH.qantas,
                }
            case this.companies.qatar:
                return dto = {
                    nome: this.companiesNome.qatar,
                    logo: this.companiesLogo.qatar,
                    url_low: this.companiesUrlLOW.qatar,
                    url_high: this.companiesUrlHIGH.qatar,
                }
            case this.companies.singapore:
                return dto = {
                    nome: this.companiesNome.singapore,
                    logo: this.companiesLogo.singapore,
                    url_low: this.companiesUrlLOW.singapore,
                    url_high: this.companiesUrlHIGH.singapore,
                }
            case this.companies.skyAirline:
                return dto = {
                    nome: this.companiesNome.skyAirline,
                    logo: this.companiesLogo.skyAirline,
                    url_low: this.companiesUrlLOW.skyAirline,
                    url_high: this.companiesUrlHIGH.skyAirline,
                }
            case this.companies.swiss:
                return dto = {
                    nome: this.companiesNome.swiss,
                    logo: this.companiesLogo.swiss,
                    url_low: this.companiesUrlLOW.swiss,
                    url_high: this.companiesUrlHIGH.swiss,
                }
            case this.companies.taag:
                return dto = {
                    nome: this.companiesNome.taag,
                    logo: this.companiesLogo.taag,
                    url_low: this.companiesUrlLOW.taag,
                    url_high: this.companiesUrlHIGH.taag,
                }
            case this.companies.tapPortugal:
                return dto = {
                    nome: this.companiesNome.tapPortugal,
                    logo: this.companiesLogo.tapPortugal,
                    url_low: this.companiesUrlLOW.tapPortugal,
                    url_high: this.companiesUrlHIGH.tapPortugal,
                }
            case this.companies.turkishAirlines:
                return dto = {
                    nome: this.companiesNome.turkishAirlines,
                    logo: this.companiesLogo.turkishAirlines,
                    url_low: this.companiesUrlLOW.turkishAirlines,
                    url_high: this.companiesUrlHIGH.turkishAirlines,
                }
            case this.companies.united:
                return dto = {
                    nome: this.companiesNome.united,
                    logo: this.companiesLogo.united,
                    url_low: this.companiesUrlLOW.united,
                    url_high: this.companiesUrlHIGH.united,
                }
            case this.companies.voePass:
                return dto = {
                    nome: this.companiesNome.voePass,
                    logo: this.companiesLogo.voePass,
                    url_low: this.companiesUrlLOW.voePass,
                    url_high: this.companiesUrlHIGH.voePass,
                }
            case this.companies.vueling:
                return dto = {
                    nome: this.companiesNome.vueling,
                    logo: this.companiesLogo.vueling,
                    url_low: this.companiesUrlLOW.vueling,
                    url_high: this.companiesUrlHIGH.vueling,
                }
            case this.companies.jetSmart:
                return dto = {
                    nome: this.companiesNome.jetSmart,
                    logo: this.companiesLogo.jetSmart,
                    url_low: this.companiesUrlLOW.jetSmart,
                    url_high: this.companiesUrlHIGH.jetSmart,
                }

            default:
                return dto = {
                    nome: 'Lines Digital 360',
                    logo: LogoDefault,
                    url_low: 'https://linesturdiag.blob.core.windows.net/logo-companhia/Default%20(Linestur)/default_low.png',
                    url_high: 'https://linesturdiag.blob.core.windows.net/logo-companhia/Default%20(Linestur)/default_low.png'
                }
        }
    },
}

export default EnumCompany;
