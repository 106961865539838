import { CardContent, CardMedia, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

export const ImprimirBilhete = props => {
    const classes = useStyles();

    return (
        <div className={classes.impressao}>
            <div className={classes.bilhete}>
                <Container>
                    <div className={classes.dados}>
                        <div>
                            <CardContent>
                                <CardMedia
                                    component="img"
                                    image="https://upload.skyteam.tur.br/Logomarcas/Agencia3026.jpg"
                                />
                            </CardContent>
                        </div>
                        <div>
                            <Typography><b>Plano de Viagem</b></Typography>
                            <Typography>Agente:   Zulma A. Torassi</Typography>
                            <Typography>E-mail:    zulma@linestur.com </Typography>
                        </div>
                    </div>
                    <div>
                        <TableContainer>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Localizador</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Status</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Sistema</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Criação</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Contatos</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">LWID7A</TableCell>
                                        <TableCell align="center">Reservado</TableCell>
                                        <TableCell align="center">Gol</TableCell>
                                        <TableCell align="center">14/02/2020 às 14:00:42</TableCell>
                                        <TableCell align="center">(48) 3045-7015 ou (48)9.9976-0516</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Passageiros</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Tipo</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Sobrenome</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Nome</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Gênero</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Nascimento</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Centro de Custo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">Adulto</TableCell>
                                        <TableCell align="center">Canutto</TableCell>
                                        <TableCell align="center">Giulia</TableCell>
                                        <TableCell align="center">Feminino</TableCell>
                                        <TableCell align="center">24/12/2001</TableCell>
                                        <TableCell align="center">SóPorDeus</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Voos</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos} >Cia</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Voo</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Origem/Destino</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Esc.</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Cl.</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Info</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Loc Cia</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">Gol</TableCell>
                                        <TableCell align="center">1225</TableCell>
                                        <TableCell align="center">PORTO ALEGRE (POA)        SAO PAULO(CGH)</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">U</TableCell>
                                        <TableCell align="center">LIGHT</TableCell>
                                        <TableCell align="center">lWID7A</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Gol</TableCell>
                                        <TableCell align="center">1964</TableCell>
                                        <TableCell align="center">SAO PAULO (CGH)        FLORIANOPOLIS(FLN)</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">U</TableCell>
                                        <TableCell align="center">LIGHT</TableCell>
                                        <TableCell align="center">lWID7A</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Assentos</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Passageiros</TableCell>
                                        <TableCell align="center" className={classes.titulos}>1225 POA CGH 18Feb</TableCell>
                                        <TableCell align="center" className={classes.titulos}>1964 CGH POA 18Feb</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell >Torassi/Zulma</TableCell>
                                        <TableCell align="center">---</TableCell>
                                        <TableCell align="center">---</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Serviços Auxiliares</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Passageiros</TableCell>
                                        <TableCell align="center" className={classes.titulos}>1225 POA CGH 18Feb</TableCell>
                                        <TableCell align="center" className={classes.titulos}>1964 CGH POA 18Feb</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">Torassi/Zulma</TableCell>
                                        <TableCell align="center">---</TableCell>
                                        <TableCell align="center">---</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Valores</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Passageiro</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">Torassi/Zulma</TableCell>
                                        <TableCell align="center">R$321,03</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center">R$321,03</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Bilhetes</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Número</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Passageiro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">9572125768720</TableCell>
                                        <TableCell align="center">Gabriela Andrade Gordilho Saraiva</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.info}>
                            <Typography > Informações para Embarque </Typography>
                            <Typography> Apresente-se em nosso checkin com 2 horas de antecedência em voos nacionais e com 3 horas de antecedência em voos internacionais. </Typography>
                            <Typography> Levar documento original: <b>Carteira de Identidade</b> para vôos nacionais.</Typography>
                            <Typography> Levar documento original: <b>Passaporte</b> e os vistos necessários para entrada no pais de destino para voos internacionais.</Typography>
                            <Typography>Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que possam ser necessários para sua viagem devem ser consultadoscom as respectivas embaixadas ou despachantes de vistos.</Typography>
                            <Typography>Verifique essa necessidade para todos os países envolvidos na viagem, mesmoaqueles onde há apenas uma escala.</Typography>
                            <Typography>Lembre-se que alguns países exigem que o passaporte tenha uma validade mínima de 6 meses paraembarcar.</Typography>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ImprimirBilhete;
